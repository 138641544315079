'use strict'

angular
  .module 'mundoAdmin'
  # .config ($stateProvider) ->
  #   $stateProvider
  #     .state 'admin',
  #       url: '/admin'
  #       templateUrl: 'mundo-admin/views/mundo-admin.tpl.html'
  #       controller: 'MundoAdminCtrl'
  #       controllerAs: 'adminCtrl'
  #       data:
  #         navigation: true
  #       onEnter: [
  #         'MundoNotifications'
  #         'MundoSocket'
  #         (MundoNotifications, MundoSocket) ->
  #           MundoNotifications.enableToasts()
  #           MundoSocket.toggleTenantEventConsumer true
  #       ]
  #       onExit: [
  #         'MundoNotifications'
  #         'MundoSocket'
  #         (MundoNotifications, MundoSocket) ->
  #           MundoSocket.toggleTenantEventConsumer false
  #           MundoNotifications.disableToasts()
  #       ]
